<template>
  <div class="commercial">
    <headert></headert>
    <div class="banner">
      <img src="../../assets/image/jiarubanner.png" alt />
    </div>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" WJgroupIntroduce-Box contentBG" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="comContent">
          <div class="comConntentLe">
            <h2>加入维佳</h2>
            <p class="line"></p>
            <div class="conlist1">
              <div class="commercialAdd">
                <span class="font">人才理念</span>
                <!-- <i class="el-icon-plus"></i> -->
              </div>
              <h3>Talent Conce</h3>
              <span class="thank">德才兼备<br />
                内外并重，任人唯贤，公平竞争</span>
            </div>
            <div>
              <router-link class="conlist2" tag="a" to="/personnelStrategy">
                <span class="font">人才战略</span>
                <i class="el-icon-plus"></i>
              </router-link>
            </div>

            <div>
              <router-link class="conlist2" tag="a" to="/personnelJoinUs">
                <span class="font">加入我们</span>
                <i class="el-icon-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="comContentRi">
            <div class="house flex">
              <div class="num">1</div>
              <div class="numRe">
                <div class="com flex">
                  <h2 class="qualityHouse">人才理念</h2>
                  <span class="eng">Talent Conce</span>
                </div>
                <p>德才兼备，内外并重，任人唯贤，公平竞争。</p>
              </div>
            </div>

            <ul class="personnel_ul ">
              <li class="flex">
                <div class="personnel_ul_img"> <img class="personnel_ul_imgOne"
                    src="../../assets/image/personnel/img1.png" alt /></div>
                <div class="personnel_ul_content">
                  <h2 class="el">严把进人关</h2>
                  <p class="eltwo">德才兼备,内外并重<br />看发展潜质,重综合素质。</p>
                </div>
              </li>
              <li class="flex">
                <div class="personnel_ul_img"> <img class="personnel_ul_imgOne"
                    src="../../assets/image/personnel/img2.png" alt /></div>
                <div class="personnel_ul_content">
                  <h2 class="el">严视育人关</h2>
                  <p class="eltwo">结合集团发展需要，围绕思想，能力，作风三方面对员工实施年度人员培训计划，人才培训计划，定项培训计划等，全方位提高员工综合水平等，以诚育才共同成长，将人员变人才。</p>
                </div>
              </li>
              <li class="flex">
                <div class="personnel_ul_img"> <img class="personnel_ul_imgOne"
                    src="../../assets/image/personnel/img3.png" alt /></div>
                <div class="personnel_ul_content">
                  <h2 class="el">严控用人关</h2>
                  <p class="eltwo">选对人，用对人；任人唯贤，公平竞争 <br /> 实施轮岗机制，打造人才循环。</p>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </el-col>
    </el-row>
    <footerb></footerb>
  </div>
</template>
<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/personnel',
        menu: {
          name: '人才招聘',
          path: '/personnel',
          list: [{
            name: '加入维佳',
            path: '/personnel',
          }, {
            name: '联系方式',
            path: '/personnelContact',
          }]
        },
        breadcrumb: [{
          name: '人才招聘',
          path: '/personnel',
        }, {
          name: '加入维佳',
          path: '/personnel',
        }, {
          name: '人才理念',
          path: '/personnel',
        }]
      };
    },
    computed: {},
    created() {},
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .commercial {
    .banner {
      width: 100%;
      height: 550px;

      img {
        width: 100%;
        height: 550px;
      }
    }

    .comContent {
      width: 100%;
      margin: 0 auto;
      display: flex;


      .comConntentLe {
        width: 312px;
        height: 422px;

        h2 {
          width: 146px;
          height: 36px;
          margin-top: 54px;
          font-size: 36px;
          line-height: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #202c38;
        }

        .line {
          width: 33px;
          margin-top: 22px;
          height: 3px;
          background: #1f2d3a;
        }

        h3 {
          margin-top: 57px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1f2d3a;
          line-height: 20px;
          margin-bottom: 21px;
        }

        .conlist2 {
          height: 69px;
          line-height: 69px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #cccc;
          margin-right: 9px;

          .font {
            height: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1f2d3a;
          }

          ::v-deep .el-icon-plus {
            margin-top: 27px;
            width: 14px;
            height: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
            line-height: 20px;
          }
        }

        .conlist1 {
          border-bottom: 1px solid #cccc;
          padding: 20px 0;

          .commercialAdd {
            margin-right: 9px;
            margin-top: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;

            span {
              height: 28px;
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #af8e68;
              line-height: 28px;
            }

            i {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #aaaaaa;
              // line-height: 14px;
            }
          }

          h3 {
            display: block;
            margin-top: 9px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #aaaaaa;
            line-height: 20px;
          }

          .thank {
            display: block;

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #202c38;
            line-height: 24px;
          }
        }
      }

      .comContentRi {
        // width: 985px;
        flex: 1;
        // height: 1582px;

        margin-top: 150px;
        margin-bottom: 150px;
        background-color: #fcfafa;
        padding: 30px 100px;

        .house {
          .num {
            font-size: 93px;
            font-family: Impact;
            font-weight: 400;
            color: #af8e68;
          }

          .numRe {
            margin-left: 30px;
          }

          .com {
            display: flex;

            .qualityHouse {
              font-size: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1f2d3a;
              line-height: 40px;

            }

            .eng {
              height: 21px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              color: #aaaaaa;
              margin-left: 11px;
            }
          }

          p {

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1F2D3A;
            line-height: 24px;
            margin-top: 10px;

          }
        }
      }
    }



  }

  .personnel_ul {
    width: 100%;

  }

  .personnel_ul li {
    margin: 20px 0;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F6EFE5;

    &:hover {
      .personnel_ul_imgOne {
        transform: scale(1.1);
      }
    }
  }

  .personnel_ul .personnel_ul_img {
    width: 320px;
    height: 150px;
    overflow: hidden;
  }

  .personnel_ul .personnel_ul_img img {
    width: 100%;
    height: 100%;
    transition: 1s;
  }

  .personnel_ul .personnel_ul_content {
    flex: 1;
    margin-left: 40px;
  }

  .personnel_ul .personnel_ul_content h2 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #202C38;
    line-height: 40px;
    height: 40px;
  }

  .personnel_ul .personnel_ul_content p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6B7B88;
    line-height: 24px;
    height: 72px;
    width: 100%;
  }
</style>
